























































































body {
    background-color: #eee;
}
#app-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#app {
    width: 100vw;
    flex-grow: 0;
    overflow: hidden;
    background-color: white;
}
.app-wrapper--desktop #app {
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}
.app-wrapper--desktop #app::before,
.app-wrapper--desktop #app::after {
    content: "";
    position: fixed;
    top: 0;
    z-index: 500;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #eee;
}
.app-wrapper--desktop #app::before {
    right: calc(50% + 25vh);
}
.app-wrapper--desktop #app::after {
    left: calc(50% + 25vh);
}
#app > .v-dialog__content,
#app > .v-overlay {
    width: 50vh;
    max-width: 100%;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.app-wrapper--mobile #app > .v-dialog__content,
.app-wrapper--mobile #app > .v-overlay {
    width: 100%;
}
.app-wrapper--desktop ::v-deep.v-dialog__content{
    width: 50vh;
    left: 50%;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
}
