










































.app-wrapper--desktop .v-bottom-navigation.v-bottom-navigation--fixed {
    width: calc(50vh - 2px);
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0) !important;
    -ms-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
}
.app-wrapper--mobile .v-bottom-navigation.v-bottom-navigation--fixed {
    width: 100%;
}

// v-bottom-navigation
.v-item-group.v-bottom-navigation {
    box-shadow: none;
    border-top: 1px solid var(--border-color);
}
.v-item-group.v-bottom-navigation .v-btn {
    min-width: auto;
    max-width: none;
    width: 100%;
    height: 100% !important;
}
.v-item-group.v-bottom-navigation .v-btn--active {
    color: var(--v-primary-base) !important;
    background-color: var(--v-primary-lighten5) !important;
}
::v-deep.v-item-group.v-bottom-navigation .v-btn .v-btn__content {
    flex-direction: row;
}
::v-deep.v-item-group.v-bottom-navigation .v-btn .v-btn__content > :not(.v-icon) {
    line-height: 1;
}
